export enum ProfileLayout {
  Card,
  FullWidth,
}

export enum ProfileImage {
  Round,
  Square,
}

export enum BadgeLayout {
  NameAndIcon,
  IconOnly,
  NameOnly,
}

export enum BadgeSize {
  Small,
  Medium,
  Large,
}

export enum IFrameEvent {
  SetViewedMember = 'members/SET_MEMBERS',
  SetGlobalSettings = 'MERGE_GLOBAL_SETTING_IN_STORE',
  FollowInState = 'members/FOLLOW_IN_STATE',
  UnfollowInState = 'members/UNFOLLOW_IN_STATE',
  EnterPublicProfilePreview = 'aboutPage/ENTER_PUBLIC_PROFILE_PREVIEW',
  LeavePublicProfilePreview = 'aboutPage/LEAVE_PUBLIC_PROFILE_PREVIEW',
}
